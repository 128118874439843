import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo, faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft, faCheck, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom'
import axios from 'axios'
import auth from './helpers/auth'
import Spinner from './Spinner'
import EventImageModal from './EventImageModal'
import EventClipModal from './EventClipModal'
import Modal from 'react-modal'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';



export class EventsTable extends Component {

    constructor(props) {
        super(props)
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleMultiDelete = this.handleMultiDelete.bind(this);

        this.state = {
            events: this.props.events,
            checkAll: false,
            checkedEvents: [],
            showEventModal: false,
        }
    }
    handlePageClick(action) {
        if (action === "previous-many")
            this.props.changePage(1)
        else if (action === "previous")
            this.props.changePage(this.props.pagingControl.currentPage - 1)
        else if (action === "next")
            this.props.changePage(this.props.pagingControl.currentPage + 1)
        else if (action === "next-many")
            this.props.changePage(this.props.pagingControl.lastPage)
    }
    handleCheckAll = e => {
        let events = this.props.events;
        let checkedEvents = [];

        if (e.target.checked) {
            // make all events as checked 
            events.map(event => {
                checkedEvents.push(event.id)
            });
        }
        this.setState({ checkedEvents, checkAll: e.target.checked })
    }
    handleCheckBoxOnChange = (e, eventId) => {
        let { checkedEvents, checkAll } = this.state;
        let events = this.props.events;
        let clickedEventId = events.filter(event => event.id === eventId).
            map(event => event.id)[0];
        if (e.target.checked) {
            checkedEvents.push(clickedEventId);
        } else {
            checkedEvents = checkedEvents.filter(id => id != eventId);
        }
        // check check all 

        if (events.length == checkedEvents.length) {
            checkAll = true;
        } else {
            checkAll = false;
        }

        this.setState({ checkedEvents, checkAll });
    }
    /**
     * opens the modal with the clip or the image
     * @param {object} event 
     * @param {object} content 
     */
    handleOpenModal(event, content) {
        this.setState({
            showEventModal: true,
            isConfirmDelete: content == 'delete'
        });
    }

    /**
     * closes the modal
     */
    handleCloseModal() {
        this.setState({ showEventModal: false });
    }

    /**
     * handles the yes answer on the delete modal. It makes a delete request
     * to the backend
     */
    handleMultiDelete() {
        console.log("Multi delete console");
        const { checkedEvents } = this.state;
        console.log(checkedEvents);
        if (checkedEvents.length == 0) {
            this.setState({ showEventModal: false });
            this.props.setAlert("danger", "Select alteast one Event before delete!!!", false);
            return;
        }

        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.post(process.env.REACT_APP_API_URL + 'events/batchdelete', { 'id': checkedEvents }, config)
            .then(response => {
                this.setState({ showEventModal: false, checkedEvents: [], checkAll: false });
                this.props.setAlert("success", "Selected Events have been deleted", true);
            })
            .catch(error => {
                this.setState({ showEventModal: false });
                this.props.setAlert("danger", "Action not accepted.", false);
            })

    }

    /** 
     * Renders the table with the events
     */
    render() {
        const { tableSpinner, events } = this.props
        const { checkedEvents, checkAll, isConfirmDelete } = this.state;
        const pagingControl = this.props.pagingControl
        return (
            <div style={{ "marginTop": "10px", paddingBottom: "20px", "width": "100%" }} className="table-responsive">
                <table className="table events-table">
                    <tbody style={wordBreak}>
                        {auth.role_id < 2 && <tr><td colSpan="19">
                            <span className="d-inline-block d-md-none">
                                <FormControlLabel
                                    control={<Checkbox style={{ color: "#ffffff" }} />}
                                    onChange={(e) => this.handleCheckAll(e)}
                                    checked={checkAll}
                                    style={{ color: "#ffffff" }}
                                />
                            </span>
                            <button className="btn btn-danger" onClick={(e) => this.handleOpenModal(e, 'delete')}>
                                <span >Delete Selected Events</span>
                            </button>
                        </td></tr>
                        }
                        <tr style={normalBreak} >
                            <th className="d-none d-md-table-cell ">
                                <FormControlLabel
                                    control={<Checkbox style={{ color: "#ffffff" }} />}
                                    onChange={(e) => this.handleCheckAll(e)}
                                    checked={checkAll}
                                    style={{ color: "#ffffff" }}
                                />
                            </th>
                            <th className="d-none d-md-table-cell">Event ID</th>
                            <th className="d-none d-md-table-cell">Company</th>
                            <th className="d-none d-md-table-cell">Site</th>
                            <th className="d-none d-md-table-cell">Farm</th>
                            <th className="d-none d-md-table-cell">Generator</th>
                            <th className="d-none d-md-table-cell">Camera</th>
                            <th className="d-none d-md-table-cell">Action</th>
                            <th className="d-none d-md-table-cell">Sound</th>
                            <th className="d-none d-md-table-cell">Timestamp</th>
                            <th className="d-none d-md-table-cell">Bird</th>
                            <th className="d-none d-md-table-cell">Warning Duration <br /><small>(seconds)</small></th>
                            <th className="d-none d-md-table-cell">Wind Speed <br /><small>(Km/h)</small></th>
                            <th className="d-none d-md-table-cell">Wind Direction <br /><small>(&#730;)</small></th>
                            <th className="d-none d-md-table-cell">Rotor Speed <br /><small>(Km/h)</small></th>
                            <th className="d-none d-md-table-cell">WTS Status</th>
                            <th className="d-none d-md-table-cell">Image</th>
                            <th className="d-none d-md-table-cell">Video</th>
                            <th className="d-none d-md-table-cell">Details</th>
                            {auth.role_id < 2 && <th className="d-none d-md-table-cell">Action</th>}
                        </tr>

                        {!tableSpinner && events.map((event) => (<EventRow event={event} key={event.id} checkedEvents={checkedEvents} handleCheckBoxOnChange={this.handleCheckBoxOnChange} setAlert={this.props.setAlert} />))}

                    </tbody>
                </table>
                <Modal
                    isOpen={this.state.showEventModal}
                    onRequestClose={this.handleCloseModal}
                    style={isConfirmDelete ? modalStyleConfirmDelete : modalStyle}
                >
                    {isConfirmDelete && <div className="card">
                        <div className="card-body" style={modalCard}>
                            <h5 className="card-title text-center">Are you sure?</h5>
                            Are you sure you want to delete Selected Events?
                            <div className="text-center" style={modalButtons}>
                                <a href="#" onClick={this.handleMultiDelete} className="btn btn-primary">Yes</a>
                                <span style={spanStyle}></span>
                                <a href="#" onClick={this.handleCloseModal} className="btn btn-danger bg-danger border-danger">Cancel</a>
                            </div>
                        </div>
                    </div>}
                </Modal>
                {tableSpinner && <Spinner />}

                {!tableSpinner && pagingControl.lastPage > 1 &&
                    <div style={pagination}>
                        <Link style={pageArrow}
                            to="#" onClick={() => this.handlePageClick("previous-many")}
                            className={pagingControl.currentPage > 1 ? "" : "disabled"}
                        >
                            <FontAwesomeIcon icon={faAngleDoubleLeft} size="lg" />
                        </Link>

                        <Link style={pageArrow}
                            to="#" onClick={() => this.handlePageClick("previous")}
                            className={pagingControl.currentPage > 1 ? "" : "disabled"}
                        >
                            <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                        </Link>

                        <span style={pageNumber}>
                            {pagingControl.currentPage}
                        </span>

                        <Link style={pageArrow}
                            to="#" onClick={() => this.handlePageClick("next")}
                            className={pagingControl.currentPage < pagingControl.lastPage ? "" : "disabled"}
                        >
                            <FontAwesomeIcon icon={faAngleRight} size="lg" />
                        </Link>

                        <Link style={pageArrow}
                            to="#" onClick={() => this.handlePageClick('next-many')}
                            className={pagingControl.currentPage < pagingControl.lastPage ? "" : "disabled"}
                        >
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                        </Link>
                    </div>
                }
            </div>
        )
    }
}


export class EventRow extends Component {
    constructor() {
        super()
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }
    /**
     * opens the modal with the clip or the image
     * @param {object} event 
     * @param {object} content 
     */
    handleOpenModal(event, content) {
        this.setState({
            showEventModal: true,
            targetEventId: event.id,
            isClip: content === 'clip',
            isImage: content === 'image',
            isConfirmDelete: content == 'delete'
        });
    }

    /**
     * closes the modal
     */
    handleCloseModal() {
        this.setState({ showEventModal: false });
    }

    /**
     * handles the yes answer on the delete modal. It makes a delete request
     * to the backend
     */
    handleDelete() {
        //delete
        const config = {
            headers: { Authorization: `Bearer ${auth.token}` }
        };
        axios.delete(process.env.REACT_APP_API_URL + 'events/' + this.props.event.id, config)
            .then(response => {
                this.setState({ showEventModal: false });
                this.props.setAlert("success", "Event " + this.props.event.id + " deleted", true);
            })
            .catch(error => {
                this.setState({ showEventModal: false });
                this.props.setAlert("danger", "Action not accepted.", false);
            })

    }

    state = {
        showEventModal: false,
        targetEventId: -1,
        isClip: false,
        isImage: false
    }
    /**
     * gets the url of the image and opens it in a new tab
     * @param {int} eventId 
     */
    openEventMedia = (eventId) => { //Deprecated!
        const route = process.env.REACT_APP_API_URL + "events/" + eventId + "/media-content"
        axios.get(route, {
            headers: { Authorization: "Bearer " + auth.token }
        }).then(response => {
            console.log("success ", response)
            const mediaUrl = response.data
            let win = window.open(mediaUrl, '_blank');
            win.focus();
        })
            .catch(error => {
                console.log("error on get_media_content ", error)
            })
    }
    getCheckedStatus = (eventId) => {
        return this.props.checkedEvents.includes(eventId);
    }
    /**
     * Renders a row of the table that contains information about the event
     */
    render() {
        Modal.setAppElement("#root")

        const { event } = this.props
        const { targetEventId, isClip, isImage, isConfirmDelete } = this.state
        // const track = event.event_type == "deterrence" ? "BMS-Warning.mp3" : "-"

        const track = event.event_type == "deterrence" ? event.horn_sound : "-"

        return (
            <tr style={normalBreak}>
                <td className="text-lg-center text-left">
                    {/*} <input type="checkbox"
                        onChange={(e) => this.props.handleCheckBoxOnChange(e, event.id)}
                        checked={event.event_checked}
                        style={sizedCheckbox}
                    />
                            { */ }
                    <FormControlLabel
                        control={<Checkbox style={{ color: "#ffffff" }} />}
                        onChange={(e) => this.props.handleCheckBoxOnChange(e, event.id)}
                        checked={(this.getCheckedStatus(event.id)) ? true : false}
                        style={{ color: "#ffffff" }}
                    />
                    <span className="d-lg-none font-weight-bold">Event # {event.id}</span>

                </td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Event ID: </span>{event.id}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Company: </span>{event.company_name}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Site: </span>{event.site_name}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Farm: </span>{event.farm_name}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Generator: </span>{event.generator_name}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Camera: </span>{event.camera_info}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Action: </span>{event.event_type}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Sound: </span>{track}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Timestamp:</span>{event.timestamp}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Bird: </span>{event.bird_name}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Event Duration<small>(seconds)</small>: </span>{event.duration}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Wind Speed
                    (Km/h): </span>{event.wind_speed}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Wind Direction
                    (˚): </span>{event.wind_direction}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">Rotor Speed:</span>{event.rotor_speed}</td>
                <td className="text-lg-center text-left"><span className="d-lg-none">WTS Status:</span>{event.wts_status}</td>
                <td className="text-center">
                    <span className="d-inline-block">
                        <button className="btn btn-link" onClick={() => this.handleOpenModal(event, 'image')}>
                            <FontAwesomeIcon icon={faImage} size="lg" />
                        </button>
                    </span>
                    <span className="d-inline-block d-md-none">
                        <button className={event.has_clip ? "btn btn-link" : "btn btn-link disabled"} onClick={() => this.handleOpenModal(event, 'clip')} >
                            <FontAwesomeIcon icon={faVideo} size="lg" />
                        </button>
                        <Link to={"/events/" + event.id + "/details"} className="btn btn-link" >
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                            {event.is_annotated && <FontAwesomeIcon icon={faCheck} size='sm' style={annotatedStyle} />}
                        </Link>
                        {auth.role_id < 2 && <button style={trashStyle} onClick={() => this.handleOpenModal(event, 'delete')}>
                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </button>}

                    </span>
                </td>
                <td style={{ textAlign: "center" }} className='d-none d-md-table-cell' >
                    <span className="d-md-block d-none">
                        <button className={event.has_clip ? "btn btn-link" : "btn btn-link disabled"} onClick={() => this.handleOpenModal(event, 'clip')} >
                            <FontAwesomeIcon icon={faVideo} size="lg" />
                        </button>
                    </span>
                </td>
                <td className='d-none d-md-table-cell'>
                    <div className="d-md-block d-none">
                        <Link to={"/events/" + event.id + "/details"} className="btn btn-link" >
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
                            {event.is_annotated && <FontAwesomeIcon icon={faCheck} size='sm' style={annotatedStyle} />}
                        </Link>
                    </div>
                </td>
                {auth.role_id < 2 && <td className="col-1 d-none d-md-table-cell">
                    <span className="d-md-block d-none">
                        <button style={trashStyle} onClick={() => this.handleOpenModal(event, 'delete')}>
                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                        </button>
                    </span>
                </td>}
                <td className="d-lg-none border-top table-separator-sm" ></td>
                <Modal
                    isOpen={this.state.showEventModal}
                    onRequestClose={this.handleCloseModal}
                    style={isConfirmDelete ? modalStyleConfirmDelete : modalStyle}
                >
                    {isImage && <EventImageModal eventid={targetEventId} onRequestClose={this.handleCloseModal} />}
                    {isClip && <EventClipModal eventid={targetEventId} onRequestClose={this.handleCloseModal} />}
                    {isConfirmDelete && <div className="card">
                        <div className="card-body" style={modalCard}>
                            <h5 className="card-title text-center">Are you sure?</h5>
                            Are you sure you want to delete Event #<strong>{this.props.event.id}</strong>?
                            <div className="text-center" style={modalButtons}>
                                <a href="#" onClick={this.handleDelete} className="btn btn-primary">Yes</a>
                                <span style={spanStyle}></span>
                                <a href="#" onClick={this.handleCloseModal} className="btn btn-danger bg-danger border-danger">Cancel</a>
                            </div>
                        </div>
                    </div>}
                </Modal>
            </tr>

        )
    }
}

const modalStyle = {
    content: {
        padding: "0 0",
    },
    overlay: { zIndex: 10000 },
}
const modalStyleConfirmDelete = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        borderStyle: "none",
        padding: "0 0"
    },
    overlay: { zIndex: 10000 },
}

const pagination = {
    textAlign: "center",
    paddingTop: "1%"
}

const pageNumber = {
    padding: "0 10px",
    color: "#ffffff"
}

const pageArrow = {
    padding: "0 4px"
}

const normalBreak = {
    wordBreak: "normal"
}

const wordBreak = {
    wordBreak: "break-word"
}

const annotatedStyle = {
    position: "absolute",
    padding: "3px",
    backgroundColor: "#3a973a",
    color: "#fff",
    borderRadius: "50%",
    border: "solid 1x #1a771a",
    zIndex: 1000
}
/* by tom **/
const spanStyle = {
    margin: "7px"
}
const modalButtons = {
    padding: "4% 0 2%"
}
const trashStyle = {
    color: "var(--main-danger)",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    border: "none",
    cursor: "pointer",
    overflow: "hidden",
    outline: "none",
}

const modalCard = {
    // padding: "25px 90px",
    // borderStyle: "none"
    border: "1px solid #dde7f1"
}


export default EventsTable