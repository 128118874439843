import React, { Component } from 'react'
import { Bar } from 'react-chartjs-2'

export class EventsBar extends Component {

    render() {
        const chartLabels = ['Detections', 'Deterrences', 'Shut Downs'];
        const chartDataSet = {
            data: [this.props.detections, this.props.deterrences, this.props.shutdowns],
            backgroundColor: ["#00FF0055", "#0000FF55", "#FF000055"],
            borderColor: ["#00FF00", "#0000FF", "#FF0000"],
            borderWidth: 1

        };
        Object.keys(this.props.extra_event_types ?? {}).map((extraEventType, extraEventTypeIndex) => {
           chartLabels.push(this.props.extra_event_types[extraEventType]['label']);
           chartDataSet.data.push(this.props.extra_event_types[extraEventType]['count']);
           chartDataSet.backgroundColor.push("#0000FF55");
           chartDataSet.borderColor.push("#FF0000");

        })

        const chartData = {
            labels: chartLabels,
            datasets: [chartDataSet]
        }

        return (
            <div className="bar-chart">
                <Bar
                    data={chartData}
                    height={200}
                    width={100}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        title: {
                            display: true,
                            text: 'Event Summary',
                            lineHeight: 1.7,
                            fontColor: "white"
                        },
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                    fontColor: "#efefef"
                                }
                            }],
                            xAxes: [{
                                ticks: { fontColor: "#efefef" }
                            }]
                        }
                    }}
                />
            </div>
        )
    }
}

export default EventsBar
